<template>
    <div class="meback">
        <div class="metop">
            <div class="metop-img-div">
                <div class="king-div">
                    <img class="king-img" src="@/assets/img/me/icon_king.png"/>
                    <img v-if="!data.img" class="head" src="@/assets/img/me/icon_head.png"/>
                    <img v-if="data.img" class="head" :src="data.img"/>
                </div>
            </div>
            <div class="metop-name">
                <div class="metop-name-name">{{data.name}}</div>
                <!-- <div class="metop-name-lab">
                    <img v-if="data.levelimg!=''" :src="data.levelimg"/>
                </div> -->
            </div>
        </div>
        <!-- <div class="melevelbg" v-if="data.levelmap">
            <div class="melevelbg-name">{{data.levelname}}</div>
            <div class="melevelbg-sp"></div>
            <div class="melevelbg-desc">发展{{data.levelmap[data.levelup]?data.levelmap[data.levelup].nums_user:"3"}}名成员升级为{{data.levelmap[data.levelup]?data.levelmap[data.levelup].level_name:"黄金会员"}}</div>
            <div style="margin-left:auto;margin-top:auto;margin-bottom:auto;">
            <div class="melevelbg-btn" @click="clickSet('share')">去升级</div>
            </div>
        </div> -->
    
        <div class="set-bg">
            <div class="set-item" v-if="data.type=='I'"  @click="clickSet('orderList')">
                <img class="item-img" src="@/assets/img/set/icon_card.png"/>
                <div class="item-name">赠送订单</div>
                <div class="item-right">></div>
            </div>
            <div class="set-sp" v-if="data.type=='I'"></div>
            <div class="set-item" v-if="data.type=='I'" @click="clickSet('teamList')">
                <img class="item-img" src="@/assets/img/set/icon_account.png"/>
                <div class="item-name">团队订单</div>
                <div class="item-right">></div>
            </div>
            <div class="set-sp" v-if="data.type=='I'"></div>
            <div class="set-item" v-if="data.type=='I'" @click="clickSet('zxqk')">
                <img class="item-img" src="@/assets/img/set/icon_ly.png"/>
                <div class="item-name">赠送统计</div>
                <div class="item-right">></div>
            </div>
            <!-- <div class="set-sp" v-if="data.type=='I'"></div> -->
            <div class="set-item" v-if="data.type=='U'" @click="clickSet('serviceList')">
                <img class="item-img" src="@/assets/img/set/icon_account.png"/>
                <div class="item-name">服务列表</div>
                <div class="item-right">></div>
            </div>
            <!-- <div class="set-sp" v-if="data.type=='I'"></div> -->
            <div class="set-item" v-if="data.type=='S'" @click="clickSet('serviceOrderList')">
                <img class="item-img" src="@/assets/img/set/icon_account.png"/>
                <div class="item-name">服务订单</div>
                <div class="item-right">></div>
            </div>
        </div>
    
        <div class="set-bg">
            <div class="set-item" @click="clickSet('about')">
                <img class="item-img" src="@/assets/img/set/icon_about.png"/>
                <div class="item-name">关于我们</div>
                <div class="item-right">></div>
            </div>
        </div>
        

        <div class="set-bg" v-if="!data.tokenFlag">
            <div class="set-item" @click="clickSet('login')">
                <img class="item-img" src="@/assets/img/set/icon_exit.png"/>
                <div class="item-name">退出</div>
                <div class="item-right">></div>
            </div>
        </div>

        <div class="set-bg" v-if="data.tokenFlag">
            <div class="set-item" @click="clickSet('login')">
                <img class="item-img" src="@/assets/img/set/icon_exit.png"/>
                <div class="item-name">登录</div>
                <div class="item-right">></div>
            </div>
        </div>
    
        <div class="service">
            <div class="service-title2">{{getTitle1()}}</div>
            <div class="service-title3">{{getTitle2()}}</div>
        </div>
        <!----------菜单开始------------->
        <Menu :emodel="menuIndex"></Menu>
        <!----------菜单结束------------->
    
        <div style="height:100px;"></div>
    </div>
    </template>
    <script setup>
    import {} from 'vant';
    import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
    import Menu from '../../components/Menu.vue'
    import {useRoute,useRouter} from 'vue-router'
    import api from '../../common/http.js';
    import {isNull,nullStr,showMessage,getTitle1,getTitle2} from '../../common/utils.js'
    
    let data=reactive({
      name:localStorage.getItem("name"),
      img:nullStr(localStorage.getItem("img")),

      type:nullStr(localStorage.getItem("type")),
      tokenFlag:isNull(localStorage.getItem("token"))
    })
    
    let route=useRoute()
    let router=useRouter()
    let menuIndex={index:4}
    
    function clickSet(type){
        if(type=='about'){
            router.push({ path: '/about' })
        }else if(type=='meedit'){
            router.push({ path: '/meedit' })
        }else if(type=='serviceList'){
            router.push({ path: '/bxservicelist' })
        }else if(type=='teamList'){
            router.push({ path: '/bxteamlist',query:{type:"all"} })
        }else if(type=='orderList'){
            router.push({ path: '/bxorderlist',query:{type:"me"} })
        }else if(type=='serviceOrderList'){
            router.push({ path: '/bxserviceorderlist'})
        }else if(type=='login'){
            localStorage.clear();
            router.push({ path: '/login'})
        }else if(type=="zxqk"){
            router.push({ path: '/bxzxqk'})
        }
    }
    
    
    </script>
    <style scoped lang="scss">
    .set-bg{
        background: #fff;
        border-radius: 0.5rem;
        margin: 1rem;
        .set-sp{
            height:1px;
            background: #ebebeb;
            margin-left: 1rem;
            margin-right: 1rem;
        }
        .set-item{
            padding:1rem;
            display:flex;
            align-items: center;
            .item-img{
                width:1.2rem;
            }
            .item-name{
                color:#061d37;
                margin-left:1rem;
            }
            .item-right{
                margin-left: auto;
                color:#9f9f9f;
            }
        }
    }
    .service{
      .service-title2{
        text-align: center;
        color: #848E9D;
        line-height: 2rem;
        align-items: center;
        font-weight: 500;
        margin-left: 1rem;
        font-size: 1.1rem;
        margin-top: 4rem;
      }
      .service-title3{
        text-align: center;
        color: #949999;
        line-height: 2rem;
        align-items: center;
        margin-left: 1rem;
        font-size: 0.9rem;
        margin-bottom: 2rem;
      }
    }
    
    .mecon-sp{
        margin:1rem;
        height:1px;
        background: #ebebeb;
    }
    .mecon{
        margin:1rem;
        display: flex;
        .meitem{
            padding:1rem;
            flex:1;
            text-align: center;
            img{
                width:100%;
            }
            div{
                margin-top:0.3rem;
                font-size:0.8rem;
            }
        }
    }
    
    .melevelbg{
        margin:1rem;
        background: url('../../assets/img/me/me_level_bg.png');
        background-size: 100% 100%;
        padding:0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        .melevelbg-name{
            font-size: 1rem;
            color:#fde0a0;
            font-weight: bold;
        }
        .melevelbg-sp{
            margin:0.5rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            background:#fde0a0;
            width:1px;
        }
        .melevelbg-desc{
            font-size: 0.8rem;
            color:#fde0a0;
            margin-top:auto;
            margin-bottom:auto;
        }
        .melevelbg-btn{
            background:#fde0a0;
            border-radius: 0.5rem;
            font-size: 0.9rem;
            margin-top:auto;
            margin-bottom:auto;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
    
        }
    }
    .metop{
        margin: 1rem;
        display: flex;
        .metop-img-div{
            position: relative;
            width:3rem;
            // border: 1px solid #fff;
            height: 3rem;
            border-radius: 50%;
            .king-div{
                position:absolute;
                margin-left: 2rem;
                margin-top: -0.5rem;
                .king-img{
                    width:1rem;
                }
                .head{
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    margin-left: -2rem;
                    margin-top: -0.8rem;
                }
            }
        }
        .metop-name{
            margin-left: 1rem;
            .metop-name-name{
    
            }
            .metop-name-lab{
                img{
                    width:5rem;
                }
            }
        }
        .metop-set{
            margin-left:auto;
            img{
                width:1.5rem;
            }
        }
    }
    .meback{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url('../../assets/img/me/me.png');
        background-size: 100% 100%;
    }
    </style>